// @ is an alias to /src
import store from '@/store';
import { computed } from 'vue';
import { onMounted, ref } from 'vue';
import bg from '@/assets/bg_tuiguang.jpeg';
import phoneCtn from '@/assets/phone_content.png'; // import qrcode from '@/assets/images/qrcode.png'

import appIcon from '@/assets/logo.png'; // import downLine from '@/assets/images/down-line.png'

export default {
  name: 'HomeView',
  components: {},

  setup() {
    const user = computed(() => {
      return store.getters.getUserInfo;
    });
    onMounted(() => {});
    const show = ref(false);

    const openUrl = () => {
      window.open('https://beian.miit.gov.cn/', '_blank');
    };

    return {
      user,
      bg,
      appIcon,
      phoneCtn,
      openUrl,
      show
    };
  }

};